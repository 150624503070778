.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: rgb(243, 126, 3) !important;
}

.ant-tabs-tab:hover {
  color: rgb(247, 152, 52) !important;
}

.ant-tabs-ink-bar {
  background: rgb(243, 126, 3) !important;
}

.ant-tabs-tab,
.ant-tabs-content {
  color: white;
}

.border {
  background-color: rgba(114, 76, 35);
  border-left: 6px solid rgb(243, 126, 3);
  padding: 10px;
  margin-bottom: 15px;
}

.sepFlexBox {
  display: flex;
  justify-content: space-between;
}

#upload_container {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
}
.upload_found {
  margin-bottom: 15px;
  width: fit-content;
}
#resume_input {
  display: none;
}
#resume_input_label {
  width: fit-content;
  background-color: rgb(243, 126, 3);
  border-radius: 9px;
  padding: 10px 15px;
  transition: background-color 0.2s;
}
#resume_input_label:hover {
  background-color: rgb(247, 152, 52);
  cursor: pointer;
}
