@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&family=Roboto:wght@400;500&display=swap);
.orange-button {
  display: block;
  outline: none;
  border: 1px solid transparent;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 12px 0px;
  margin: 0px 10px;
  padding: 6px 10px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  min-height: 48px;
  background-color: rgb(243, 126, 3);
  color: rgb(255, 255, 255);
  border-radius: 4px;
  font-size: 20px;
  transition: background-color 0.2s;
}
.orange-button:hover {
  background-color: rgb(247, 152, 52);
  color: rgb(255, 255, 255);
  cursor: pointer;
}

.ant-menu-item-selected {
  background-color: rgb(243, 126, 3) !important;
}

.ant-menu-item:hover {
  background-color: rgb(247, 152, 52) !important;
}

.flex {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.MuiCard-root {
  min-height: 300px;
  text-align: center;
  background-color: rgb(120, 119, 119) !important;
  color: white !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
}

.ant-layout-content {
  padding: 40px !important;
}

.MuiCardHeader-title {
  font-family: sharp-sans !important;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: rgb(243, 126, 3) !important;
}

.ant-tabs-tab:hover {
  color: rgb(247, 152, 52) !important;
}

.ant-tabs-ink-bar {
  background: rgb(243, 126, 3) !important;
}

.ant-tabs-tab,
.ant-tabs-content {
  color: white;
}

.border {
  background-color: rgba(114, 76, 35);
  border-left: 6px solid rgb(243, 126, 3);
  padding: 10px;
  margin-bottom: 15px;
}

.sepFlexBox {
  display: flex;
  justify-content: space-between;
}

#upload_container {
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 100%;
}
.upload_found {
  margin-bottom: 15px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
#resume_input {
  display: none;
}
#resume_input_label {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  background-color: rgb(243, 126, 3);
  border-radius: 9px;
  padding: 10px 15px;
  transition: background-color 0.2s;
}
#resume_input_label:hover {
  background-color: rgb(247, 152, 52);
  cursor: pointer;
}

.App {
  text-align: center;
}

* {
  font-family: "Roboto", sans-serif !important;
}

h1,
h2,
title {
  font-family: "Poppins", sans-serif !important;
}

body {
  background-color: #363636;
}

.ant-layout-content {
  padding: 25px;
  background-color: #363636;
  min-height: 0 !important;
  min-height: initial !important;
}

.linkList li {
  margin-bottom: 20px;
}

a {
  color: rgb(243, 126, 3);
}

a:hover {
  color: rgb(247, 152, 52);
}

.ant-card {
  min-height: 200px;
  text-align: center;
  background-color: rgb(120, 119, 119) !important;
}

.ant-card p,
.ant-card-head-title {
  color: white;
}

.ant-card-hoverable:hover {
  box-shadow: 0 5px 12px 4px rgba(226, 224, 224, 0.4) !important;
}

.apply-button {
  outline: none;
  border: 1px solid transparent;
  margin: 0px 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 3px 12px 0px;
  padding: 4px 10px;
  min-height: 48px;
  background-color: rgb(243, 126, 3);
  color: rgb(255, 255, 255);
  border-radius: 4px;
  font-size: 15px;
}
.apply-button:hover {
  background-color: rgb(247, 152, 52);
}

.flex {
  display: flex;
  justify-content: center;
}

* {
  box-sizing: border-box;
  font-family: sharp-sans;
  transition: all 0.2s ease-in-out
}

body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: sharp-sans;
  font-weight: lighter;
  src: url(/static/media/samsungsharpsans.9b469dd6.otf)
}

@font-face {
  font-family: sharp-sans;
  font-weight: normal;
  src: url(/static/media/samsungsharpsans-medium.83045435.otf);
}

@font-face {
  font-family: sharp-sans;
  font-weight: bold;
  src: url(/static/media/samsungsharpsans-bold.52e5256e.otf)
}
