@import "~antd/dist/antd.css";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@500&family=Roboto:wght@400;500&display=swap");
.App {
  text-align: center;
}

* {
  font-family: "Roboto", sans-serif !important;
}

h1,
h2,
title {
  font-family: "Poppins", sans-serif !important;
}

body {
  background-color: #363636;
}

.ant-layout-content {
  padding: 25px;
  background-color: #363636;
  min-height: initial !important;
}

.linkList li {
  margin-bottom: 20px;
}

a {
  color: rgb(243, 126, 3);
}

a:hover {
  color: rgb(247, 152, 52);
}
