.flex {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.MuiCard-root {
  min-height: 300px;
  text-align: center;
  background-color: rgb(120, 119, 119) !important;
  color: white !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: space-between !important;
}

.ant-layout-content {
  padding: 40px !important;
}

.MuiCardHeader-title {
  font-family: sharp-sans !important;
}
